<template>
  <b-card>
    <b-table
      ref="table"
      :items="fetchData"
      :fields="columns"
      :current-page="currentPage"
      :per-page="perPage"
      class="position-relative"
      responsive
      empty-text="No Announcements"
      show-empty
    >
      <template #cell(interactions)="{item}">
        <b-row>
          <b-col
            lg="4"
          >
            <b-link
              :to="{ name: `${routesPrefix}view-announce`, params: {id:item.id} }"
            >
              <span class="font-weight-bold">
                {{ item.commentsCount }}
                <feather-icon icon="MessageSquareIcon" />
              </span>
            </b-link>
          </b-col>
          <b-col
            lg="4"
          >
            <b-link
              disabled
            >
              <span class="font-weight-bold">
                {{ item.likesCount }}
                <feather-icon icon="ThumbsUpIcon" />
              </span>
            </b-link>
          </b-col>
          <b-col
            lg="4"
          >
            <b-link
              disabled
            >
              <span class="font-weight-bold">
                {{ item.sharesCount }}
                <feather-icon icon="Share2Icon" />
              </span>
            </b-link>
          </b-col>
        </b-row>
      </template>
      <template #cell(status)="{item}">
        <span
          v-if="item.status"
          class="font-weight-bold"
        >
          <feather-icon
            icon="CircleIcon"
            class="active-circle"
          />
          Active
        </span>
        <span
          v-else
          class="font-weight-bold"
        >
          <feather-icon
            icon="CircleIcon"
          />
          Inactive
        </span>
      </template>
      <template #cell(actions)="{item}">
        <b-dropdown
          variant="link"
          no-caret
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>
          <b-dropdown-item
            :to="{ name: `${routesPrefix}view-announce`, params: {id:item.id} }"
          >
            <feather-icon icon="FileTextIcon" />
            <span class="align-middle ml-50"> View </span>
          </b-dropdown-item>

          <b-dropdown-item
            :to="{ name: `${routesPrefix}announce-feedback-reports`, params: {id:item.id} }"
          >
            <feather-icon icon="FlagIcon" />
            <span> Feedback Reports </span>
          </b-dropdown-item>

          <share-deep-link
            :id="item.id"
            route="/annpuncement profile"
          />

          <toggle-status
            v-if="$can('ActivityController','publishStatus')"
            :item="item"
            :toggle-status="toggleStatus"
          />

          <b-dropdown-item
            :to="{ name: `${routesPrefix}edit-announce`, params: {id:item.id} }"
          >
            <feather-icon icon="EditIcon" />
            <span> Edit </span>
          </b-dropdown-item>

          <b-dropdown-item
            @click="deleteAnnouncement(item)"
          >
            <feather-icon
              class="text-danger"
              icon="TrashIcon"
            />
            <span
              class="align-middle ml-50 text-danger"
            >
              Delete
            </span>
          </b-dropdown-item>

        </b-dropdown>
      </template>
    </b-table>
  </b-card>
</template>
<script>
import { avatarText } from '@core/utils/filter'

import handleAlerts from '@/common/compositions/common/handleAlerts'
import ShareDeepLink from '../common/Table/ShareDeepLink.vue'
import ToggleStatus from '../common/Table/ToggleStatus.vue'

export default {
  name: 'AnnouncementsTable',
  components: {
    ShareDeepLink,
    ToggleStatus,
  },
  props: {
    fetchData: { type: Function, default: () => [] },
    currentPage: { type: Number, default: 1 },
    perPage: { type: Number, default: 1 },
    routesPrefix: { type: String, default: '' },
  },
  data() {
    return {
      columns: [
        'id',
        { key: 'title', sortable: true },
        { key: 'interactions' },
        { key: 'status', label: 'state', sortable: true },
        { key: 'startsAt', sortable: true },
        { key: 'endsAt', sortable: true },
        { key: 'cta_type', label: 'action type' },
        { key: 'actions' },
      ],
      deleteEndPoint: `${process.env.VUE_APP_ACTIVITIES_BASE_URL}/internalops/announcement/`,
    }
  },
  setup() {
    const {
      successfulOperationAlert, successfulDeletionAlert, errorAlert, confirmOperation,
    } = handleAlerts()
    return {
      successfulOperationAlert, successfulDeletionAlert, errorAlert, confirmOperation,
    }
  },
  methods: {
    avatarText,
    deleteAnnouncement(item) {
      if (this.isAnnouncHasInteraction(item)) {
        this.errorAlert('Announcement can\'t be deleted because it has interactions')
        return
      }

      this.confirmOperation().then(() => {
        this.$activities.delete(`/internalops/announcement/${item.id}`).then(() => {
          this.successfulDeletionAlert('Announcement is deleted successfully')
          this.$refs.table.refresh()
        })
      })
    },
    isAnnouncHasInteraction(item) {
      return item.commentsCount || item.likesCount || item.sharesCount
    },
    toggleStatus(item) {
      this.$activities.post(`/internalops/announcement/${item.id}/toggle`).then(() => {
        const operationType = item.status ? 'Deactivated' : 'Activated'
        item.status = !item.status
        this.successfulOperationAlert(`Announcement is ${operationType} successfully`)
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.active-circle {
  color:rgb(0, 128, 38);
  background-color:rgb(0, 128, 38);
  border-radius:50%
}
</style>
