<template>
  <div>
    <div class="text-right mb-1">
      <add-resource
        :resource-name="`${permissionPrefix}ActivityController`"
        :add-route-name="`${routesPrefix}add-announce`"
      />
    </div>

    <b-row>
      <b-col>
        <b-card header="Search">
          <b-row class="mb-1">
            <b-col md="6">
              <search
                placeholder="Title"
                :search-query.sync="announcementsCriteria.title"
                @refresh="refreshTable"
              />
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <date-filter :date.sync="announcementsCriteria.date" />
            </b-col>

            <b-col md="4">
              <custom-vue-select
                id="status-filter"
                placeholder="Please select status"
                :value-reducer="option=>option.value"
                text-field="text"
                :options="[
                  {
                    value: null,
                    text: 'Both',
                  },
                  {
                    value: true,
                    text: 'Active',
                  },
                  {
                    value: false,
                    text: 'Inactive',
                  },
                ]"
                :selected.sync="announcementsCriteria.status"
              />
            </b-col>

            <b-col
              md="2"
              class="mt-1 mt-md-0"
            >
              <search-button @refresh="refreshTable" />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row align-h="center">
      <div
        v-if="loading"
        class="loader"
      />
    </b-row>

    <announcements-table
      ref="parentTable"
      v-bind="{
        fetchData:getAnnouncements,
        currentPage:pagination.currentPage,
        perPage:pagination.perPage,
        routesPrefix
      }"
    />

    <pagination
      :total-rows="pagination.totalRows"
      :per-page="pagination.perPage"
      :current-page.sync="pagination.currentPage"
    />

  </div>
</template>

<script>
import AddResource from '@/common/components/common/Table/AddResource.vue'
import Search from '@/common/components/common/Table/Search.vue'
import SearchButton from '@/common/components/common/Table/SearchButton.vue'
import AnnouncementsTable from '@/common/components/Announcements/AnnouncementsTable.vue'
import Pagination from '@/common/components/common/Table/Pagination.vue'
import DateFilter from '@/common/components/common/Table/DateFilter.vue'
import paginationData from '@/common/compositions/common/paginationData'
import CustomVueSelect from '@/common/components/common/FormInputs/CustomVueSelect.vue'

export default {
  name: 'AnnouncementsList',
  components: {
    AddResource,
    DateFilter,
    Search,
    SearchButton,
    AnnouncementsTable,
    Pagination,
    CustomVueSelect,
  },
  props: {
    routesPrefix: { type: String, default: '' },
    permissionPrefix: { type: String, default: '' },
    isSystemAdmin: { type: Boolean, default: false },
  },
  data() {
    return {
      announcementsList: [],
      announcementsCriteria: {
        title: null,
        entityId: this.isSystemAdmin ? null : this.$store.getters['mainEntity/getEntityId'],
        date: null,
      },
      loading: true,
    }
  },
  setup() {
    const { pagination } = paginationData()
    return { pagination }
  },
  methods: {
    getAnnouncements(ctx) {
      return this.$activities.get('/internalops/announcement', {
        params: {
          ...this.announcementsCriteria,
          page: this.pagination.currentPage,
          orderParameter: ctx.sortBy,
          orderDir: ctx.sortDesc ? 'DESC' : 'ASC',
        },
      })
        .then(res => {
          const announcements = res.data.data

          this.pagination.totalRows = res.data.pagination.total
          return announcements || []
        })
        .catch(() => [])
        .finally(() => {
          this.loading = false
        })
    },
    refreshTable() {
      this.$refs.parentTable.$refs.table.refresh()
    },
  },
}
</script>
<style lang="scss">
.list-group {
    display: contents;
}
</style>
