<template>
  <b-form-group>
    <b-input-group>
      <flat-pickr
        :value="date"
        class="form-control"
        placeholder="Date"
        :config="{dateFormat: 'Y-m-d', altFormat: 'j F, Y', altInput:true}"
        @on-close="setDate"
      />
      <b-input-group-append>
        <b-button
          variant="danger"
          size="sm"
          @click="resetDate"
        >
          Clear
        </b-button>
      </b-input-group-append>
    </b-input-group>
  </b-form-group>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'

export default {
  name: 'DateRangeFilter',
  components: {
    flatPickr,
  },
  props: {
    date: { type: [String, Date], default: null },
  },
  methods: {
    setDate(selectedDate) {
      const date = this.$moment(selectedDate[0]).format('YYYY-MM-DD')
      this.$emit('update:date', date)
    },
    resetDate() {
      this.$emit('update:date', null)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
