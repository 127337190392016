var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"text-right mb-1"},[_c('add-resource',{attrs:{"resource-name":(_vm.permissionPrefix + "ActivityController"),"add-route-name":(_vm.routesPrefix + "add-announce")}})],1),_c('b-row',[_c('b-col',[_c('b-card',{attrs:{"header":"Search"}},[_c('b-row',{staticClass:"mb-1"},[_c('b-col',{attrs:{"md":"6"}},[_c('search',{attrs:{"placeholder":"Title","search-query":_vm.announcementsCriteria.title},on:{"update:searchQuery":function($event){return _vm.$set(_vm.announcementsCriteria, "title", $event)},"update:search-query":function($event){return _vm.$set(_vm.announcementsCriteria, "title", $event)},"refresh":_vm.refreshTable}})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('date-filter',{attrs:{"date":_vm.announcementsCriteria.date},on:{"update:date":function($event){return _vm.$set(_vm.announcementsCriteria, "date", $event)}}})],1),_c('b-col',{attrs:{"md":"4"}},[_c('custom-vue-select',{attrs:{"id":"status-filter","placeholder":"Please select status","value-reducer":function (option){ return option.value; },"text-field":"text","options":[
                {
                  value: null,
                  text: 'Both',
                },
                {
                  value: true,
                  text: 'Active',
                },
                {
                  value: false,
                  text: 'Inactive',
                } ],"selected":_vm.announcementsCriteria.status},on:{"update:selected":function($event){return _vm.$set(_vm.announcementsCriteria, "status", $event)}}})],1),_c('b-col',{staticClass:"mt-1 mt-md-0",attrs:{"md":"2"}},[_c('search-button',{on:{"refresh":_vm.refreshTable}})],1)],1)],1)],1)],1),_c('b-row',{attrs:{"align-h":"center"}},[(_vm.loading)?_c('div',{staticClass:"loader"}):_vm._e()]),_c('announcements-table',_vm._b({ref:"parentTable"},'announcements-table',{
      fetchData:_vm.getAnnouncements,
      currentPage:_vm.pagination.currentPage,
      perPage:_vm.pagination.perPage,
      routesPrefix: _vm.routesPrefix
    },false)),_c('pagination',{attrs:{"total-rows":_vm.pagination.totalRows,"per-page":_vm.pagination.perPage,"current-page":_vm.pagination.currentPage},on:{"update:currentPage":function($event){return _vm.$set(_vm.pagination, "currentPage", $event)},"update:current-page":function($event){return _vm.$set(_vm.pagination, "currentPage", $event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }